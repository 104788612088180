import React, { useState } from "react";
import logoHor from "../assets/images/logo-horisontal.svg";
import logoVer from "../assets/images/logo-vertical.svg";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import Loader from "./elements/loader";
import ErrorMsg from "./elements/ErrorMsg";

function Unsubscribe() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const { t, i18n } = useTranslation();
  const logo = window.innerWidth >= 768 ? logoHor : logoVer;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const email = searchParams.get("email") || "";
  const code = searchParams.get("code") || "";

  const unsubHandler = async () => {
    setLoading(true);
    setError(false);
    const formData = new FormData();
    formData.append("email", email);
    formData.append("code", code);
    formData.append("locale", i18n.language);
    try {
      const result = await fetch(
        `${process.env.REACT_APP_WP_API_BASE}/changemkr/subscribers/unsubscribe`,
        {
          method: "POST",
          body: formData,
        }
      );
      if (result.status === 200) {
        setError(false);
        setSuccess(true);
      } else {
        setSuccess(false);
        setError(true);
      }
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  return (
    <div className="container">
      <header className="header">
        <img src={logo} className="logo" alt="logo" />
      </header>

      <main>
        <h1>
          {!success && t("you-sure")}
          {success && t("sorry-leave")}
        </h1>
        <p>
          {!success && t("will-miss")}
          {success && t("unsubscribed")}
        </p>
        <div className="formWrapper">
          {!success && (
            <button onClick={unsubHandler} className={"subscribeBtn unsub"}>
              {!loading && t("unsub-btn")}
              {loading && <Loader />}
            </button>
          )}
          {success && <h3>{t("unsub-confirm")}</h3>}
          {error && <ErrorMsg>{t("unsub-error")}</ErrorMsg>}
        </div>
      </main>

      <footer className="footer">
        © {new Date().getFullYear()} Changemkr {t("by")}{" "}
        <a href={"https://digjourney.com/"}>DigJourney</a>
      </footer>
    </div>
  );
}

export default Unsubscribe;
