import React from "react";
import { useTranslation } from "react-i18next";


function LoginBtn() {

  const { t } = useTranslation();

  return (
    <div className={ 'login-btn-container' }>
      <button
        onClick={ () => window.location.href = `${ process.env.REACT_APP_BASE_URL }/login` }
        className={ 'subscribeBtn login-btn' }
      >
        { t('login btn caption') }
      </button>
    </div>
  );
}

export default LoginBtn;