import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEng from "./locales/en/translation.json";
import translationSv from "./locales/sv/translation.json";
import { initReactI18next } from 'react-i18next';

const detectionOpts = {
  order: [ 'cookie', 'localStorage', 'htmlTag' ],
  lookupCookie: 'lang',
  lookupLocalStorage: 'lang',
  caches: [ 'localStorage' ]
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({

    detection: detectionOpts,

    resources: {
      en: {
        translations: translationEng
      },
      sv: {
        translations: translationSv
      }
    },
    fallbackLng: "en",
    // debug: true,

    ns: [ "translations" ],
    defaultNS: "translations",

    keySeparator: false,

    interpolation: {
      escapeValue: false,
      formatSeparator: ","
    },

    react: {
      wait: true
    }
  })
  .then(() => document.querySelector('html').setAttribute('lang', i18n.language));

export default i18n;