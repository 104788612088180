import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { ReactComponent as GlobeIcon } from "../assets/images/16-Globe.svg";
import { useCookies } from "react-cookie";

function LangSwitcher() {

  const { i18n } = useTranslation();
  const [ lang, setLang ] = useState( i18n.language );
  const [cookie, setCookie, removeCookie] = useCookies(['lng']);

  useEffect(() => {
      setCookie('lng', i18n.language, {
          domain: `.${window.location.hostname}`,
          secure: true
      });
  }, [lang]);

  const languageHandler = (event) => {
    let newLang = event.target.value;
    setLang( newLang );
    i18n.changeLanguage(newLang).then(()=> document.querySelector('html').setAttribute('lang', i18n.language));
  };

  return (
    <div className='language-switch'>
      <span className='globeIcon'><GlobeIcon/></span>
      <input
        checked={ lang === 'en' }
        name="language"
        id="en_lang"
        onChange={ (e) => languageHandler(e) }
        value="en"
        type="radio"/>
      <label htmlFor="en_lang">EN</label>
      <span> / </span>
      <input
        name="language"
        id="sv_lang"
        value="sv"
        checked={ lang === 'sv' }
        type="radio"
        onChange={ (e) => languageHandler(e) }
      />
      <label htmlFor="sv_lang">SV</label>
    </div>
  );
}

export default LangSwitcher;
