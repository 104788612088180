import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Loader from "./elements/loader";
import ErrorMsg from "./elements/ErrorMsg";


function SubscribeForm() {
  const { t, i18n } = useTranslation();

  const [ loading, setLoading ] = useState(false);
  const [ success, setSuccess ] = useState(false);
  const [ error, setError ] = useState(undefined);

  const { getFieldProps, handleSubmit, handleChange, errors, touched, isValid, dirty, values } = useFormik({
    initialValues: {
      email: '',
      accept_terms: false
    },
    validationSchema: Yup.object({
      email: Yup.string().required(t("email-validation-error")).email(t("email-validation-error")),
      accept_terms: Yup.boolean().isTrue()
    }).pick([ 'email', 'accept_terms' ]),
    onSubmit: async values => {
      setLoading(true);
      const formData = new FormData();
      formData.append('email', values.email);
      formData.append('subscribe', values.accept_terms);
      formData.append('locale', i18n.language);
      try {
        const result = await fetch(`${ process.env.REACT_APP_WP_API_BASE }/changemkr/subscribers/subscribe`, {
          method: "POST",
          body: formData
        });
        if (result.status === 200) {
          setError(false);
          setSuccess(true);
        } else {
          setSuccess(false);
          setError(result.status);
        }
      } catch (e) {
        setError(e.status);
      }
      setLoading(false);
    }
  });

  useEffect(() => {
    setError(undefined);
  }, [ values ]);

  if (success) return (
    <div className={ `successWrapper` }>{ t('submit-button-confirmation') }</div>
  );

  return (
    <form onSubmit={ handleSubmit } className={ 'subscribeForm' }>
      <label htmlFor={ 'email' } className={ 'email' }>
        { t("form-title") }
      </label>
      <div className={ 'btnGroup' }>
        <input
          id={ 'email' }
          type={ 'email' }
          placeholder={ t('email') }
          data-failed={ touched.email && !!errors.email }
          data-verified={ touched.email && !errors.email }
          { ...getFieldProps("email") }
          className={ 'email' }
        />
        <button
          type={ 'submit' }
          disabled={ !dirty || !isValid || !!error }
          className={ 'subscribeBtn' }
        >
          { !loading && t("submit-button") }
          { loading && <Loader/> }
        </button>
      </div>
      <label htmlFor={ 'accept_terms' } className={ 'gdpr' }>
        <input
          id={ 'accept_terms' }
          type={ 'checkbox' }
          onChange={ handleChange }
        />
        <span className={ 'gdprCheckmark' }/>
        <span>{ t("GDPR_checkbox-start") }</span>
        <a href="https://platform.changemkr.com/privacy-policy">Privacy Policy</a>
        <span>{ t("GDPR_checkbox-end") }</span>
        <span className={ 'requiredIcon' }>*</span>
      </label>
      { (error || (touched.email && errors.email)) &&
      <ErrorMsg>
        { (error === 400 && t('fetch-error')) || (error === 409 && t('email-exist-error')) || (error === 500 && t('server-error')) || (touched.email && errors.email && t('email-validation-error')) }
      </ErrorMsg> }
    </form>
  );
}

export default SubscribeForm;
