import React from "react";
import logoHor from "../assets/images/logo-horisontal.svg";
import logoVer from "../assets/images/logo-vertical.svg";
import { useTranslation } from "react-i18next";
import SubscribeForm from "./SubscribeForm";

function Main() {
  const { t } = useTranslation();
  const logo = window.innerWidth >= 768 ? logoHor : logoVer;
  return (
    <div className="container">
      <header className="header">
        <img src={logo} className="logo" alt="logo" />
      </header>

      <main>
        <h1>
          {process.env.REACT_APP_IS_STUB ? (
            t("Coming Soon!")
          ) : (
            <>
              <div>{t("title")}</div>
              <div className={"oneliner"}>{t("oneliner")}</div>

            </>
          )}
        </h1>
        <div className={"breadtext"}>{t("breadtext")}</div>
        <div className="formWrapper">
          {!process.env.REACT_APP_IS_STUB && <SubscribeForm />}
        </div>
      </main>

      <footer className="footer">
        © {new Date().getFullYear()} Changemkr {t("by")}{" "}
        <a href={"https://digjourney.com/"}>DigJourney</a>
      </footer>
    </div>
  );
}

export default Main;
