import React from 'react';
import { render } from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import Overlay from "./components/Overlay";
import LangSwitcher from "./components/LangSwitcher";
import Main from "./components/Main";
import './localization/i18n';
import Unsubscribe from "./components/Unsubscribe";
import LoginBtn from "./components/LoginBtn";

render(
  (<React.StrictMode>
    <Router>
      <Switch>
        <Route exact path="/">
          <Overlay/>
          <LoginBtn/>
          <LangSwitcher/>
          <Main/>
        </Route>
        { !process.env.REACT_APP_IS_STUB && <Route path="/unsubscribe">
          <Overlay/>
          <LangSwitcher/>
          <Unsubscribe/>
        </Route> }
        <Redirect to={ '/' }/>
      </Switch>
    </Router>


  </React.StrictMode>),
  document.getElementById('root')
);

reportWebVitals();
